import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import NumberLabel from '../../../components/worksafe-vic/NumberLabel';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "healthpoint-transactions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#healthpoint-transactions",
        "aria-label": "healthpoint transactions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HealthPoint transactions`}</h1>
    <p>{`Healthpoint supports both private health insurance and overseas visitor/health cover transactions.`}</p>
    <p>{`The following five Healthpoint transaction types are supported on machines:`}</p>
    <ul>
      <li parentName="ul">{`Rebate estimates`}</li>
      <li parentName="ul">{`Claims`}</li>
      <li parentName="ul">{`Cancellations`}</li>
      <li parentName="ul">{`Reporting`}</li>
      <li parentName="ul">{`Reconciliation and Settlement`}</li>
    </ul>
    <p>{`However, the Transaction SDK currently only supports integrated Rebate estimates and Claims transactions. Other functions can be accessed directly on the machine.`}</p>
    <p>{`To process Healthpoint transactions, set:`}</p>
    <ul>
      <li parentName="ul">{`The `}<strong parentName="li">{`platform`}</strong>{` attribute to `}<strong parentName="li">{`funder`}</strong>{`, and`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`funder`}</strong>{` attribute to `}<strong parentName="li">{`phi`}</strong>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "rebate-estimates-quotes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#rebate-estimates-quotes",
        "aria-label": "rebate estimates quotes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Rebate estimates (Quotes)`}</h3>
    <p>{`Healthpoint transactions can be submitted as claims or rebate estimates - otherwise known as quotes.`}</p>
    <p>{`Successful claims result in a transfer of funds from a private health fund to a provider, whilst a rebate estimate will be a point in time assessment of fund member benefits and will not transfer funds.`}</p>
    <p>{`The request payloads for Rebate estimates and Claims are the same except for one flag. By default, a HealthPoint transaction will be a claim. To run the transaction as a rebate estimate you will need to set `}<strong parentName="p">{`isRebateEstimate`}</strong>{` to true.`}</p>
    <h3 {...{
      "id": "claims",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#claims",
        "aria-label": "claims permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claims`}</h3>
    <p>{`The process to complete a claim is as follows:`}</p>
    <NumberLabel number="1" mdxType="NumberLabel">Set the following details in the PMS and send to the SDK:</NumberLabel>
    <ul>
      <li parentName="ul">{`Provider number`}</li>
      <li parentName="ul">{`Patient details`}</li>
      <li parentName="ul">{`Item details`}</li>
    </ul>
    <NumberLabel number="2" mdxType="NumberLabel">  Swipe the patient health fund card.</NumberLabel>
    <NumberLabel number="3" mdxType="NumberLabel"> The claim is processed by the fund and a response is returned within 45 seconds.</NumberLabel>
    <NumberLabel number="4" mdxType="NumberLabel">  The claim summary is shown in the SDK and the machine will print a receipt with item level details, which might list a gap for each item.</NumberLabel>
    <NumberLabel number="5" mdxType="NumberLabel">  For Approved &quot;00&quot; responses, you will need to ask the patient if they Accept or Reject the claim which is actioned in the SDK.</NumberLabel>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A. On Accept,<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; i. The claim is finalised.<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. A second claim receipt is printed as the patient copy.<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. The patient should sign the receipt which must be retained by the provider for 2 years<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. If one or more gap amounts were returned, the aggregated gap amount can be sent to the terminal as an EFTPOS purchase transaction via an &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;interactive method, like cash.<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;B. On Reject,<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. A Void transaction is sent to the Health Fund to invalidate the Claim.<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. A Void receipt is printed by the machine.<br />
    <NumberLabel number="6" mdxType="NumberLabel">  The Transaction SDK will return a response to the PMS with the claim outcome and if applicable, the EFTPOS payment outcome.</NumberLabel>
    <h1 {...{
      "id": "providers-and-locations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#providers-and-locations",
        "aria-label": "providers and locations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Providers and locations`}</h1>
    <p>{`A claim requires one valid provider. Multiple providers are not supported - each must be submitted as a separate claim.`}</p>
    <p>{`Provider and practice location details can be set with a single `}<strong parentName="p">{`providerNumber`}</strong>{` attribute. Importantly, this provider number must be defined and active in Tyro Health and for the given business API Key. Provider numbers must also be registered and active with HealthPoint which will be arranged by the Tyro Health team. Provider numbers which are not registered in Tyro Health or for the given business API key will error. Only one provider can claim per invoice.`}</p>
    <p>{`Provider number formats can vary by professional category and issuing organisation. In general, the provider number will be defined as follows:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Professional category`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Issued to`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Issuing body`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Format`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Examples`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Audiology, Chiropractic, Dental, Dietetics, GP, Medical Specialist, Occupational Therapy, Optometry, Osteopathy, Physiotherapy, Podiatry, Psychology, Speech Pathology`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Individual, unique for each location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NNNNNNAA - up to 8 characters. Note: leading zeros can be omitted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2429591L 0012341A 12341A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Acupuncture, Counselling, Exercise Physiology, Myotherapy, Remedial Massage`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Individual, can be unique for each location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medibank, Private Health Fund or HealthPoint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Varies - up to 16 characters. Note: leading zeros can be omitted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2429591L 0012341A 12341A`}</td>
        </tr>
      </tbody>
    </table>
    <h1 {...{
      "id": "patient-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#patient-details",
        "aria-label": "patient details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patient details`}</h1>
    <p>{`A claim requires one valid patient. Multiple patients are not supported - each must be submitted as a separate claim. A claim requires:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`firstName`}</strong>{` (optional): up to 40 characters, and only contain alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (`}{`'`}{`) and hyphen (- ) characters. Spaces must not appear before or after apostrophes, hyphens, other spaces or the supplied value. At least one alpha or numeric character required.`}</li>
      <li parentName="ul"><strong parentName="li">{`lastName`}</strong>{` (optional): up to 40 characters, and only contain alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (`}{`'`}{`) and hyphen (- ) characters. Spaces must not appear before or after apostrophes, hyphens, other spaces or the supplied value. At least one alpha or numeric character required.`}</li>
      <li parentName="ul"><strong parentName="li">{`dob`}</strong>{` (optional): date of birth in YYYY-MM-DD format.`}</li>
      <li parentName="ul"><strong parentName="li">{`reference`}</strong>{` : the unique patient indicator/reference or card rank as shown on the private health fund card. Usually two digits which can be set a leading zero and for some funds, the reference can be 00.`}</li>
      <li parentName="ul"><strong parentName="li">{`refId`}</strong>{` (optional): an optional but highly recommended patient unique reference as set by you, such as a UUID/GUID. This value is used to uniquely identify a patient in the Tyro Health platform. Omitting refId will cause duplicate patient records to be created in Tyro Health and could make patient searches more difficult for providers who also use the Tyro Health portal to check on status of a claim and remittance. If supplied and valid, this value will pre-populate patient details from the patient record in the business as set in any prior claim. Any patient details set for an existing patient record will update those values and override what was previously stored.`}</li>
      <li parentName="ul"><strong parentName="li">{`mobile`}</strong>{` (optional): patient mobile number either in international or national notation and used for uniquely searching for patients in the Tyro Health provider portal.`}</li>
    </ul>
    <h2 {...{
      "id": "items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#items",
        "aria-label": "items permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Items`}</h2>
    <p>{`A claim requires at least one claimableItem. Up to 16 claimableItems with different dates of service can be included in a single claim.`}</p>
    <p>{`Several thousand unique item codes - also referred to as service items, item numbers, material numbers or paycodes - are supported by Tyro Health for HealthPoint claims. This includes those defined by private health funds and for GP/Specialist overseas claims, a subset of Medicare Benefits Schedule (MBS). Lists of valid item codes for each profession are posted under `}{`"`}{`modality item codes`}{`"`}{`: `}<a parentName="p" {...{
        "href": "https://www.dedalus.com/anz/healthpoint/"
      }}>{`https://www.dedalus.com/anz/healthpoint/`}</a></p>
    <p>{`Private and overseas insurers allow providers to determine their own fees for services. However, payment will only be made up to a maximum benefit amount for each service. Approved claims will return a `}{`"`}{`benefit paid`}{`"`}{`, and the remaining amount will appear as a gap on the invoice. The provider is expected to charge the patient gap fees for amounts not covered by the fund.`}</p>
    <p>{`A valid claim will contain the following item attributes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`reference`}</strong>{` (optional): optional but recommended, a line item unique reference as nominated by you such as a UUID/GUID. Assists with reconciliation where multiple of the same item code and service date are set in a claim. Up to 128 alphanumeric characters are permitted which must be unique for each service item in a claim.`}</li>
      <li parentName="ul"><strong parentName="li">{`serviceDate`}</strong>{` : date of service in YYYY-MM-DD format. Past service dates are supported but most private health insurers expect services to be claimed on the date of service and may decline claims for past service dates.`}</li>
      <li parentName="ul"><strong parentName="li">{`itemCode`}</strong>{` : up to 5 characters. The item code must be applicable for a given service date and provider registered professional category. For PHI and MBS items, leading zeros are permitted or may be omitted. Items which are not known or invalid for a given service date or profession will be flagged with an error at the item level.`}</li>
      <li parentName="ul"><strong parentName="li">{`price`}</strong>{` : gross - including GST if applicable - price each in $XX.XX format. This amount will be sent to the health fund. HealthPoint and funds have no way to differentiate GST taxable from non-taxable supply items.`}</li>
      <li parentName="ul"><strong parentName="li">{`clinicalCode`}</strong>{` (optional): usually a two digit number which can designate: These may be required by some private health funds. Only applicable for:`}
        <ul parentName="li">
          <li parentName="ul">{`Dentistry: as two digit Tooth ID`}</li>
          <li parentName="ul">{`Psychology: as two digit Primary Condition code`}</li>
          <li parentName="ul">{`Occupational therapy:`}
            <ul parentName="li">
              <li parentName="ul">{`digit one as Primary Condition indicator`}</li>
              <li parentName="ul">{`digit two as Diagnostic Intervention indicator`}</li>
            </ul>
          </li>
          <li parentName="ul">{`For HCF only: the ICD short code used in the `}{`"`}{`More for`}{`"`}{` program which can be three alphanumeric characters.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`If the provider claims multiple items with the same item code, those should be listed as separate items. There is no support for quantity with HealthPoint claim items.`}</p>
    <h1 {...{
      "id": "claim-flows-and-states",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#claim-flows-and-states",
        "aria-label": "claim flows and states permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claim flows and states`}</h1>
    <p>{`Once successfully submitted, a claim will be categorised into one of the following status:`}<br /></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Status`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Highlight mdxType="Highlight">{`Approved`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One or more claim items accepted and approved by the health fund. Settlement not yet issued to provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Highlight mdxType="Highlight">{`Completed`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An Approved claim where payment has been issued to the provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Highlight mdxType="Highlight">{`Declined`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The health fund responded with Rejected and the claim benefit will not be paid.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Highlight mdxType="Highlight">{`Cancelled`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tyro health have initiated a cancellation request on the claim and the health fund has  responded with a successful cancellation`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Claims will be processed instantly by the health insurer using their straight-through adjudication method. Those claims will return an approved or declined status within 45 seconds and the SDK will return that status via a callback.`}</p>
    <p>{` Additionally, for each claim, a partner can optionally nominate one or more webhooks which fire depending on the claim status. Each successfully submitted claim will also return, via SDK callback, a Tyro Health unique transaction Id which can be used to periodically poll for current status.`}</p>
    <h3 {...{
      "id": "cancellation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#cancellation",
        "aria-label": "cancellation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Cancellation`}</h3>
    <p>{`Claim cancellation is supported on the same day as claim approval prior to settlement. For HealthPoint claims, this is usually 23:55 AET but can be as early as 20:55 for select funds.`}</p>
    <p>{`For example:`}</p>
    <ul>
      <li parentName="ul">{`A successful HealthPoint claim processed at 09:00 can be cancelled the same day at 21:00.`}</li>
      <li parentName="ul">{`A successful HealthPoint claim processed at 09:00 cannot be cancelled the following day.`}</li>
    </ul>
    <p>{`Cancellations can be initiated via the Medipass portal by following the below steps:`}</p>
    <NumberLabel number="1" mdxType="NumberLabel"> Login to the Medipass portal, select the transaction and invoke the cancellation function.</NumberLabel>
    <NumberLabel number="2" mdxType="NumberLabel">The patient will be prompted to swipe their health fund card.</NumberLabel>
    <NumberLabel number="3" mdxType="NumberLabel"> A Cancellation request is sent to the fund.</NumberLabel>
    <NumberLabel number="4" mdxType="NumberLabel"> A response is obtained within 45 seconds.</NumberLabel>
    <NumberLabel number="5" mdxType="NumberLabel"> If successful, a Cancellation receipt is printed which shows the fund&#39;s responses to the Cancellation of each item and to the Claim as a whole.</NumberLabel>
    <h3 {...{
      "id": "refunds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#refunds",
        "aria-label": "refunds permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Refunds`}</h3>
    <p>{`Refunds are available for payment transactions but not HealthPoint claims.`}</p>
    <p>{`There is currently no option to refund HealthPoint claims. Claims that have been settled require the providers and business to contact the member health fund and initiate a manual refund with that fund.`}</p>
    <h1 {...{
      "id": "receipt-and-statement-requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#receipt-and-statement-requirements",
        "aria-label": "receipt and statement requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Receipt and statement requirements`}</h1>
    <p>{`For claims, the Tyro machine will automatically generate a printed statement for the patient to sign . If the claim is approved, the provider should ask the patient to sign the statement and retain the paper copy for 2 years. A 2nd copy will be printed on approval for the patient.`}</p>
    <p>{`If a gap EFTPOS payment transaction was completed, that will be printed as a separate payment receipt for the cardholder.`}</p>
    <p>{`Integrated receipts - where the machine omits printing of claim statements and EFTPOS receipts - is currently only available in the Tyro iClient or TTA integration method.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      